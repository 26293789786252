<template>
  <div class="columns is-multiline">
    <div class="column is-2-widescreen is-12-tablet">
      <div class="field" v-if="!el.is['is-small']">
        <label class="label">{{ $t("ui.per_page") }}</label>
        <div class="select">
          <select v-model="per_page">
            <option :value="10">10</option>
            <option :value="20">20</option>
            <option :value="50">50</option>
          </select>
        </div>
      </div>
      <div class="field search">
        <label class="label" v-if="!el.is['is-small']">{{ $t("ui.search") }}</label>
        <div class="columns is-mobile" v-else>
          <div class="column is-narrow">
            <div 
              class="dropdown"
              :class="{'is-active': open}"
              @click="open = !open"
            >
              <div class="dropdown-trigger">
                <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                  <span class="icon is-small">
                    <svg-icon icon="sort" />
                  </span>
                  <span class="icon is-small" style="transform: rotate(270deg)">
                    <svg-icon icon="chevron-left" />
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content">
                  <a class="dropdown-item" v-for="head in mobileHeaders" :key="head" @click="sort(head)">
                    {{ $t("order." + head) }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <input
          type="text"
          class="input"
          v-model="reference"
          :placeholder="$t('overview.search')"
        />
      </div>
    </div>
    <div class="column" v-if="orders.length > 0 && el.is['is-small']">
      <orders-mobile :orders="filteredOrders" :more="more" @nextPage="nextPage"></orders-mobile>
    </div>
    <div class="column" v-else-if="orders.length > 0">
      <div>
        <table
          class="table is-fullwidth is-hoverable is-striped orders-overview"
          :class="{ 'is-narrow': el.is['is-small'] || el.is['is-medium-only'] }"
        >
          <thead>
            <tr>
              <th
                v-for="head in headers"
                :key="head"
                @click="sort(head)"
                :class="{ 'is-active': orderBy === head }"
              >
                <p>{{ $t("order." + head) }}</p>
                <i
                  v-if="orderBy === head"
                  class="arrow"
                  :class="direction ? 'down' : 'up'"
                />
                <i
                  v-else
                  class="arrow"
                  :class="'down'"
                />
                
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="order in filteredOrders">
              <order-row @addOrder="addOrderPickUp" :order="order" :key="order.id" />
            </template>
          </tbody>
        </table>
      </div>
      <button
        v-if="more"
        class="button is-text"
        style="font-weight: bold"
        @click="nextPage"
      >
        {{ $t("overview.more") }}
      </button>
    </div>
    <div v-else-if="loading">
      <h1 class="title">{{ $tc("overview.loading", type) }}</h1>
    </div>
    <div v-else>
      <h1 class="title">{{ $tc("overview.none", type) }}</h1>
    </div>
  </div>
</template>
<script>
import debounce from "lodash-es/debounce";
import responsiveMixin from "../utilities/responsiveMixin";
import OrderRow from "../components/Orders/OrderRow";
import OrdersMobile from "../views/OrdersMobile";

export default {
  name: "Orders",
  data() {
    return {
      open: false,
      // true is desc, false is asc
      orders: [],
      loading: false,
      page: 1,
      reference: null,
      per_page: 10,
      more: true,
      orderBy: null,
      // true is desc, false is asc
      direction: true,
      headers: [
        "id",
        "datumtijdorder",
        "projectreferentie",
        "kl_contactpers",
        "datumtijddeadline",
        "state",
        "repeat",
        "pick-up"
      ],
      mobileHeaders: [
        "id",
        "datumtijdorder",
        "projectreferentie",
        "kl_contactpers",
        "datumtijddeadline",
        "state",
      ],
    };
  },
  computed: {
    type() {
      return this.$t(this.$route.meta.title);
    },
    filteredOrders() {
      if (this.reference) {
        return this.orders.filter(
          (order) =>
            order.id.toString().includes(this.reference) ||
            order.projectreferentie
              .toLowerCase()
              .includes(this.reference.toLowerCase())
        );
      }

      return this.orders;
    },
    query() {
      let type = "state=open,done";
      switch (this.$route.name) {
        case "quotes":
          type = "state=quote";
          break;
        case "templates":
          type = "state=template";
          break;
        default:
          type = "state=open,done";
          break;
      }
      const query = this.reference ? `reference=${this.reference}` : null;
      const requestedPage = `page=${this.page}`;
      const per_page = `per_page=${this.per_page}`;

      const orderBy = this.orderBy
        ? `orderBy=${this.orderBy}&direction=${this.direction ? "desc" : "asc"}`
        : null;

      return `?${[query, requestedPage, type, per_page, orderBy]
        .filter((string) => string)
        .join("&")}`;
    },
  },
  methods: {
    updateReference(val){
      this.reference = val;
    },
    nextPage(){
      this.page += 1;
    },
    addOrderPickUp(){
        let orders = [];
        this.orders.filter(order => {
        if(order.check == true){
          orders.push(order);
        }
      })
      this.$store.commit('ADD_ORDERS',orders);
    },
    sort(type) {
      if(type == "repeat" || type == "pick-up"){
        return;
      }
      this.orders = [];
      this.loading = true;
      if (this.orderBy === type) {
        this.direction = !this.direction;
      } else {
        this.orderBy = type;
        this.direction = true;
      }
    },
    retrieveData: debounce(async function(event) {
      try {
        this.loading = true;
        const {
          data: { data, current_page, last_page },
        } = await this.$http.get(`/order${this.query}`);
        if(current_page == 1){this.orders = [];}
        if (data.length > 0) {
          const dataToPush = data.filter(
            (order) => !this.orders.find((oldOrder) => oldOrder.id === order.id)
          );

          this.orders.push(...dataToPush);
        }
        this.more = current_page >= last_page ? false : true;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    }, 500),
  },
  watch: {
    query(val, oldVal) {
      if (val !== oldVal) {
        this.retrieveData();
      }
    },
    per_page() {
      this.orders = [];
      this.page = 1;
    },
  },
  mixins: [responsiveMixin],
  created() {
    this.retrieveData();
  },
  components: {
    OrderRow,
    OrdersMobile,
  },
};
</script>
<style lang="scss">
.order-row {
  cursor: pointer;
}

.orders-overview {
  th.is-active {
    border-bottom-color: #000;
  }
}

.arrow {
  margin-left: 0.5rem;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.orders-head th {
  padding-top: 2rem;
}

.search{
  display: flex;
  position:-webkit-sticky; 
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>
