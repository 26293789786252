var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order.datumtijddeadline != null)?_c('router-link',{staticClass:"order-row",attrs:{"tag":"tr","to":{
    name: 'order',
    params: { orderId: _vm.order.id },
    query: { hash: _vm.order.hash },
  }}},[_c('td',[_c('router-link',{attrs:{"to":{
        name: 'order',
        params: { orderId: _vm.order.id },
        query: { hash: _vm.order.hash },
      }}},[_c('div',{staticClass:"order-id",class:{ 'needs-approval': _vm.order.needs_approval },attrs:{"title":_vm.order.id + (_vm.order.needs_approval ? ' Goedkeuring nodig' : '')}},[_vm._v("\n        "+_vm._s(_vm.order.id)+"\n        "),(_vm.order.spoed)?_c('span',{staticClass:"icon is-medium"},[_c('svg-icon',{attrs:{"icon":"truck"}})],1):_vm._e()])])],1),_vm._v(" "),_c('td',[_vm._v("\n    "+_vm._s(_vm._f("date")(_vm.order.datumtijdorder,{ year: "numeric", month: "short", day: "numeric" }))+"\n  ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm.order.projectreferentie))]),_vm._v(" "),_c('td',{domProps:{"innerHTML":_vm._s(_vm.order.kl_contactpers)}}),_vm._v(" "),_c('td',[_vm._v("\n    "+_vm._s(_vm._f("date")(_vm.order.datumtijddeadline,{year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit",}))+"\n  ")]),_vm._v(" "),_c('td',[_vm._v("\n    "+_vm._s(_vm.$t(
        "progress." +
          (_vm.order.state.toLowerCase() === "factureren"
            ? "delivered"
            : _vm.order.state.toLowerCase())
      ))+"\n  ")]),_vm._v(" "),_c('td',[_c('button',{staticClass:"icon is-medium",attrs:{"tabindex":"-1"},on:{"click":function($event){$event.preventDefault();return _vm.copyOrder($event)}}},[_c('svg-icon',{attrs:{"icon":"duplicate"}})],1)]),_vm._v(" "),_c('td',[_c('div',{staticClass:"control"},[_c('label',{staticClass:"checkbox",attrs:{"tabindex":"1"}},[(_vm.order.actief_subprocesid=='1029')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.order.check),expression:"order.check"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.order.check)?_vm._i(_vm.order.check,null)>-1:(_vm.order.check)},on:{"click":function($event){$event.preventDefault();return _vm.addOrder(_vm.order)},"change":function($event){var $$a=_vm.order.check,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.order, "check", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.order, "check", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.order, "check", $$c)}}}}):_vm._e()])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }