<template>
  <div class="mobile-orders">
    <div class="columns">
      <div class="column">
        <div v-for="order in orders" :key="order.id">
          <router-link
            :to="{
              name: 'order',
              params: { orderId: order.id },
              query: { hash: order.hash },
            }"
            v-if="order.datumtijddeadline != null"
          >
            <div class="card mobile mb-1" >
              <div class="card-content">
                <div class="content">
                  <!-- <button class="icon is-medium is-pulled-right" @click.prevent="copyOrder(order)"><svg-icon icon="duplicate" /></button> -->
                  <div class="title">
                    {{ order.projectreferentie }}
                  </div>
                  <div class="subtitle">
                    <div class="leftbox">
                      {{ order.id }}-<i>{{ order.korteorderomschrijving }}</i>
                    </div>
                    <div class="rightbox">
                      {{ order.state }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <button
          v-if="more"
          class="button is-text"
          style="font-weight: bold"
          @click="nextPage"
        >
          {{ $t("overview.more") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
// const translateSide = (side) => {
//   switch (side) {
//     case "boven":
//       return "top";
//     case "onder":
//       return "bottom";
//     case "links":
//       return "left";
//     case "rechts":
//       return "right";
//   }
// };

export default {
  props: {
    orders: {
      type: Array,
    },
    more: {
      type: Boolean,
    },
  },
  name: "OrdersMobile",
  methods: {
    // async copyOrder(order) {
    //   const { data } = await this.$http.get(`/order/${order.id}`);
    //   this.$store.dispatch("createPrint", data.product_id);
    //   this.$store.dispatch(
    //     "addCopiesCopyOrder",
    //     data.copies.map((copy) => {
    //       return {
    //         originals: copy.pages,
    //         quantity: copy.qty,
    //         width: copy.width,
    //         height: copy.height,
    //         sides: copy.sides,
    //         abaa: copy.abaa,
    //         id: copy.id,
    //         remark: copy.remark,
    //       };
    //     })
    //   );
 
    //   if (this.$store.getters.needFinish && data.activities.length > 0) {
    //     this.$store.dispatch(
    //       "addFinishes",
    //       data.activities
    //         .map((finish) => {
    //           return {
    //             name: finish.omschrijving,
    //             finishing_id: finish.product_id,
    //             side: translateSide(finish.opmerking),
    //           };
    //         })
    //         .filter(
    //           (finish) =>
    //             this.$store.getters.currentFinishes.findIndex(
    //               (currentFinish) =>
    //                 currentFinish.finishing_id === finish.finishing_id
    //             ) !== -1
    //         )
    //     );
    //   }
    //   this.$router.push({ name: "checkout" });
    // },
    nextPage(){
      this.$emit('nextPage');
    }
  },
};
</script>
