<template>
  <router-link
    tag="tr"
    class="order-row"
    :to="{
      name: 'order',
      params: { orderId: order.id },
      query: { hash: order.hash },
    }"
    v-if="order.datumtijddeadline != null"
  >
    <td>
      <router-link
        :to="{
          name: 'order',
          params: { orderId: order.id },
          query: { hash: order.hash },
        }"
      >
        <div
          class="order-id"
          :class="{ 'needs-approval': order.needs_approval }"
          :title="order.id + (order.needs_approval ? ' Goedkeuring nodig' : '')"
        >
          {{ order.id }}
          <span class="icon is-medium" v-if="order.spoed">
            <svg-icon icon="truck" />
          </span>
        </div>
      </router-link>
    </td>
    <td>
      {{
        order.datumtijdorder
          | date({ year: "numeric", month: "short", day: "numeric" })
      }}
    </td>
    <td>{{ order.projectreferentie }}</td>
    <td v-html="order.kl_contactpers"></td>
    <td>
      {{ order.datumtijddeadline | date({year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit",}) }}
    </td>
    <td>
      {{
        $t(
          "progress." +
            (order.state.toLowerCase() === "factureren"
              ? "delivered"
              : order.state.toLowerCase())
        )
      }}
    </td>
    <td>
      <button tabindex="-1" class="icon is-medium" @click.prevent="copyOrder">
        <svg-icon icon="duplicate" />
      </button>
    </td>
    <td>
      <div class="control">
        <label class="checkbox" tabindex="1" >
          <input v-if="order.actief_subprocesid=='1029'" type="checkbox" v-model="order.check" @click.prevent="addOrder(order)">
        </label>
      </div>
    </td>
  </router-link>
</template>
<script>
import { CREATE_PRINT } from "../../store/mutation-types";

const translateSide = (side) => {
  switch (side) {
    case "boven":
      return "top";
    case "onder":
      return "bottom";
    case "links":
      return "left";
    case "rechts":
      return "right";
  }
};

export default {
  data(){
    return{
      selected: false,
      checkedlist:[]
    }
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
      addOrder(order){
        setTimeout(() => {
                  order.check = !order.check;
                  this.$emit('addOrder');
        })
    },
    async copyOrder() {
      const { data } = await this.$http.get(`/order/${this.order.id}`);
      this.$store.dispatch("createPrint", data.product_id);
      this.$store.dispatch(
        "addCopiesCopyOrder",
        data.copies.map((copy) => {
          return {
            originals: copy.pages,
            quantity: copy.qty,
            width: copy.width,
            height: copy.height,
            sides: copy.sides,
            abaa: copy.abaa,
            id: copy.id,
            remark: copy.remark,
          };
        })
      );

      if (this.$store.getters.needFinish && data.activities.length > 0) {
        this.$store.dispatch(
          "addFinishes",
          data.activities
            .map((finish) => {
              return {
                name: finish.omschrijving,
                finishing_id: finish.product_id,
                side: translateSide(finish.opmerking),
              };
            })
            .filter(
              (finish) =>
                this.$store.getters.currentFinishes.findIndex(
                  (currentFinish) =>
                    currentFinish.finishing_id === finish.finishing_id
                ) !== -1
            )
        );
      }
      this.$router.push({ name: "checkout" });
    },
  },
};
</script>
<style lang="scss">
@import "~vars";

.order-row .order-id {
  display: flex;
  align-items: center;

  &.needs-approval::after {
    content: "";
    background-color: $info;
    height: 1rem;
    width: 1rem;
    border-radius: 10px;
    margin-left: 0.5rem;
  }
}

.order-row .order-id .icon {
  margin-left: 1rem;
}

.order-row .checkbox{
  width: 100%;
  height: 100%;
  padding-left: 40%;
}
</style>
